import React, { useEffect } from 'react'
import { useUser, LeshenPhoneCTA } from '@leshen/gatsby-theme-leshen'
import { Typography, Modal } from '@leshen/ui'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

const ZipPackageSection = () => {
  const { userData, userLocation, setUserData } = useUser()
  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        // don't have to hit tv data until availability submit because tv data is only for redirects
        const packageData = await zipLogic(userLocation.zipCode, 'internet')

        setUserData({
          loading: false,
          showModal: false,
          highspeedCenturylinkPackages: packageData,
        })
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <>
      <Modal
        isShowing={availablePlans.showModal}
        onClose={() =>
          setUserData({
            loading: false,
            showModal: false,
            highspeedCenturylinkPackages:
              availablePlans?.highspeedCenturylinkPackages,
          })
        }
        size="lg"
        closeTimeoutMS={0}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2">
            {`CenturyLink doesn't have services in your area, but our trained agents can help you find another option!`}
          </Typography>
          <LeshenPhoneCTA color="primary">Call an Agent</LeshenPhoneCTA>
        </div>
      </Modal>
    </>
  )
}

export default ZipPackageSection
